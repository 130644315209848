$color_1: black;
$color_2: #900909;
$color_3: #ffffff;
$color_4: #ccc;
$color_5: #fff;
$color_6: rgb(51, 51, 51);
$color_7: #e3e1e1;
$color_8: #dee5d6;
$color_9: #8a8a8a;
$color_10: #333333;
$color_11: #585858;
$color_12: #000;
$color_13: #747270;
$color_14: #E6E6E6;
$color_15: #1f3e4f;
$color_16: inherit;
$color_17: #ff0000;
$color_18: #D6D5D5;
$color_19: #ADADAD;
$color_20: #FFF;
$color_21: #999;
$color_22: #4f4844;
$color_23: #444;
$color_24: #888;
$color_25: #A60000;
$color_26: #4e4e4b;
$color_27: #1e4276;
$color_28: #226464;
$color_29: #9f9f9f;
$color_30: #5a3245;
$color_31: white;
$font-family_1: 'Roboto', sans-serif;
$font-family_2: inherit;
$font-family_3: 'Open Sans', sans-serif;
$background-color_1: #226464;
$background-color_2: rgba(0,0,0,0);
$background-color_3: rgba(0, 0, 0, 0.1);
$primary: #900909;
$secondary: #226464;

/*--
Author: W3layouts
Author URL: http://w3layouts.com
License: Creative Commons Attribution 3.0 Unported
License URL: http://creativecommons.org/licenses/by/3.0/
--*/
/*-----start-header----*/
/* i.call{
	width: 60px;
	height: 70px;
	background: url('assets/images/img-sprite.png') no-repeat -35px 0px;
	display: inline-block;
}
/*i.callmsg{
	width: 70px;
	height: 70px;
	background: url('assets/images/img-sprite.png') no-repeat -98px 0px;
	display: inline-block;
}
/* i.interact{
	width: 130px;
	height: 130px;
	background: url('assets/images/img-sprite.png') no-repeat -170px 0px;
	display: inline-block;
}
/*i.dt{
	width: 80px;
	height: 70px;
	background: url('assets/images/img-sprite.png') no-repeat -311px 0px;
	display: inline-block;
}
/*i.zoom{
	width: 70px;
	height: 70px;
	background: url('assets/images/img-sprite.png') no-repeat -401px -2px;
	display: inline-block;
}
/*i.arrow{
	width: 35px;
	height: 35px;
	background: url('assets/images/img-sprite.png') no-repeat -471px 0px;
	display: inline-block;
}
/*i.smartphone{
	width: 70px;
	height: 70px;
	background: url('assets/images/img-sprite.png') no-repeat 0px -63px;
	display: inline-block;
	margin-bottom: 2em;
}

 */
/*--clients--*/
/*** Navigation ***/
/********************/
/*-----*/
/*-- services-page-starts-here --*/
/*-- about-page-starts-here --*/
/* General button style (reset) */
/* Button 1c */
/* Button 1d */
/*-- contact-page-starts-here --*/
/*-- blog-page-starts-here --*/
/*** Page numbers ***/
/*-- error-page --*/
/*-- responsive-design starts-here --*/
body {
  padding: 0;
  margin: 0;
  font-family: $font-family_1;
  background: #fff;
  color: $color_1;
  a {
    transition: 0.5s all;
    -webkit-transition: 0.5s all;
    -moz-transition: 0.5s all;
    -o-transition: 0.5s all;
    -ms-transition: 0.5s all;
  }
}
input[type="button"] {
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
  -ms-transition: 0.5s all;
}
input[type="submit"] {
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
  -ms-transition: 0.5s all;
}
h1 {
  margin: 0;
}
h2 {
  margin: 0;
}
h3 {
  margin: 0;
}
h4 {
  margin: 0;
}
h5 {
  margin: 0;
}
h6 {
  margin: 0;
}
p {
  margin: 0;
}
ul {
  margin: 0;
  padding: 0;
}
label {
  margin: 0;
}
a {
  color: $color_2;
  text-decoration: underline;
  font-weight: bold;
  &:hover {
    text-decoration: none;
    outline: none;
  }
  &:focus {
    text-decoration: none;
    outline: none;
  }
}
.header {
  background: #ffffff;
  padding: 1em 0;
  border-bottom: 5px solid #900909;
}
.logo {
  float: left;
  margin-top: 13px;
}
.header-right {
  float: right;
  h4 {
    color: $color_3;
    font-size: 2.1em;
    font-weight: 700;
    text-align: right;
    margin-bottom: 31px;
  }
}
i.phone {
  width: 35px;
  height: 35px;
  background: url('assets/images/img-sprite.png') no-repeat 0px 0px;
  display: inline-block;
  vertical-align: sub;
  margin-right: 5px;
}
.top-menu {
  ul {
    li {
      display: inline-block;
      margin: 0 0.8em;
      &:nth-child(6) {
        margin-right: 0;
      }
      a {
        &:nth-child(6) {
          padding-right: 0;
        }
        text-decoration: none;
        color: $color_4;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 1.1em;
        padding: 10px 5px;
        &:hover {
          border-bottom: 5px solid #900909;
          color: $color_2;
        }
      }
      a.active {
        border-bottom: 5px solid #900909;
        color: $color_2;
      }
    }
  }
}
.banner {
  background: url('assets/images/regclubbanner.jpg') no-repeat 0px 0px;
  background-size: cover;
  min-height: 494px;
  position: relative;
}
.banner-info {
  h2 {
    color: $color_5;
    font-size: 2.8em;
    font-weight: 300;
    letter-spacing: 3px;
    margin-bottom: 0.7em;
  }
  p {
    color: $color_5;
    font-size: 1.5em;
    font-weight: 400;
    line-height: 1.5em;
  }
  background: rgba(34, 100, 100, 0.7);
  width: 40%;
  position: absolute;
  top: 17%;
  left: 14%;
  padding: 2.5em 2em;
  a {
    color: $color_5;
    font-size: 1.25em;
    font-weight: 500;
    padding: 10px 22px;
    border: 2px solid #fff;
    text-decoration: none;
    text-transform: uppercase;
    margin-top: 2em;
    display: block;
    width: 38%;
    z-index: 1;
    background: rgba(92, 162, 204, 0);
  }
}
.subscribe-section {
  background: url('assets/images/subscribe-bg.jpg') no-repeat 0px 0px;
  background-size: cover;
  min-height: 250px;
}
.services-section {
  padding: 4em 0;
  h3 {
    color: $color_6;
    font-size: 2.7em;
    font-weight: 300;
    letter-spacing: 2px;
    margin-bottom: 0.8em;
  }
  h4 {
    color: $color_5;
    font-weight: 300;
    font-size: 1.9em;
    letter-spacing: 2px;
  }
  p {
    color: $color_5;
    font-weight: 300;
    font-size: 1.05em;
    line-height: 1.4em;
    margin: 8px 0 15px 0;
  }
}
.services-section-grid1-top {
  background: #900909;
  padding: 15px 25px;
  margin-bottom: 15px;
}
i.user {
  width: 70px;
  height: 70px;
  background: url('assets/images/img-sprite.png') no-repeat -2px -137px;
  display: inline-block;
  margin-bottom: 2em;
}
i.global {
  width: 70px;
  height: 70px;
  background: url('assets/images/img-sprite.png') no-repeat -78px -78px;
  display: inline-block;
  margin-bottom: 2em;
}
.arrow1 {
  margin-top: 15px;
}
.arrow2 {
  margin-top: 22px;
}
.arrow3 {
  margin-top: 50px;
}
.arrow4 {
  margin-top: 18px;
}
.arrow5 {
  margin-top: 18px;
}
.icons {
  margin-top: 2em;
}
.icons2 {
  margin-top: 7em;
}
.services-section-grid1-bottom {
  background: #747270;
  padding: 15px 25px;
}
.services-section-grid2 {
  background: #226464;
  padding: 15px 25px;
  width: 33.333%;
}
.services-section-grid3-top {
  background: #747270;
  padding: 15px 25px;
  margin-bottom: 15px;
}
.services-section-grid3-bottom {
  background: #900909;
  padding: 15px 25px;
}
.services-section-grid1 {
  width: 33.333%;
  padding: 0 15px 0 0;
}
.services-section-grid3 {
  width: 33.333%;
  padding: 0 0 0 15px;
  a {
    color: $color_7;
  }
}
.services-section-grids {
  a {
    color: $color_7;
  }
  display: inline-flex;
  @media (max-width: 800px) {
    display: block;
  }
}
.icon-left {
  float: left;
}
.icon-right {
  float: right;
}
.subscribe {
  h3 {
    color: $color_5;
    font-size: 2.8em;
    font-weight: 300;
    letter-spacing: 2px;
    margin-bottom: 1em;
  }
  input[type="text"] {
    width: 43%;
    color: $color_5;
    font-size: 15px;
    padding: 12px;
    border: none;
    outline: none;
    background: #226464;
    border: 2px solid #fff;
  }
  a {
    text-decoration: none;
    border: 2px solid #fff;
    background: #226464;
    color: $color_5;
    font-size: 14px;
    letter-spacing: 2px;
    margin-left: -6px;
    padding: 13px 30px;
    &:hover {
      background: #000;
    }
  }
  text-align: right;
}
.book {
  text-align: center;
}
.subscribe-section-grids {
  padding-top: 3.5em;
}
.our-clients-head {
  h3 {
    color: $color_6;
    font-size: 2.9em;
    font-weight: 300;
    letter-spacing: 2px;
    margin-bottom: 1em;
  }
}
#flexiselDemo1 {
  display: none;
}
#flexiselDemo2 {
  display: none;
}
#flexiselDemo3 {
  display: none;
}
.nbs-flexisel-container {
  position: relative;
  max-width: 100%;
  position: relative;
  max-width: 100%;
}
.nbs-flexisel-ul {
  position: relative;
  width: 9999px;
  margin: 0px;
  padding: 0px;
  list-style-type: none;
  text-align: center;
  position: relative;
  width: 9999px;
  margin: 0px;
  padding: 0px;
  list-style-type: none;
  text-align: center;
}
.nbs-flexisel-inner {
  overflow: hidden;
  overflow: hidden;
  float: left;
  width: 100%;
}
.nbs-flexisel-item {
  float: left;
  margin: 0px;
  padding: 0px;
  cursor: pointer;
  position: relative;
  float: left;
  margin: 0px;
  padding: 0px;
  position: relative;
  cursor: pointer;
  >img {
    position: relative;
    max-width: 300px;
    max-height: 100px;
  }
  img {
    position: relative;
  }
}
.nbs-flexisel-nav-left {
  width: 40px;
  height: 35px;
  position: absolute;
  cursor: pointer;
  z-index: 100;
  margin-top: 9em;
  left: 24em;
  background: url('assets/images/img-sprite.png') no-repeat -75px -176px;
}
.nbs-flexisel-nav-right {
  width: 40px;
  height: 35px;
  position: absolute;
  cursor: pointer;
  z-index: 100;
  margin-top: 9em;
  right: 24em;
  background: url('assets/images/img-sprite.png') no-repeat -114px -176px;
}
.our-clients {
  padding: 3.5em 0;
}
.client-text {
  p {
    color: $color_8;
    font-size: 1.75em;
    font-weight: 300;
    line-height: 1.6em;
  }
  padding: 0 8em;
}
.testimonials-section {
  background: url('assets/images/t-bg.jpg') no-repeat 0px 0px;
  background-size: cover;
  min-height: 494px;
}
.testimonials-section-head {
  h3 {
    color: $color_9;
    font-size: 2.8em;
    font-weight: 300;
    letter-spacing: 2px;
  }
  margin: 3em 0;
}
.text {
  background: #fff;
  padding: 12px;
  h4 {
    color: $color_10;
    font-size: 1.3em;
    font-weight: 500;
    margin-bottom: 5px;
    text-transform: uppercase;
  }
  p {
    color: $color_11;
    font-size: 1em;
    text-transform: uppercase;
  }
}
.description {
  background: rgba(245, 243, 238, 0.67);
  padding: 3em 0 0 0;
  min-height: 305px;
}
.member1 {
  width: 23%;
  float: left;
  padding: 0;
  img {
    width: 100%;
  }
}
.contact-section {
  padding: 3em 0;
  h3 {
    color: $color_6;
    font-size: 2.9em;
    font-weight: 300;
    letter-spacing: 2px;
    margin-bottom: 1em;
  }
}
.contact-top {
  p {
    color: $color_12;
    font-size: 1.3em;
    font-weight: 500;
  }
  display: inline-flex;
}
.contact-section {
  padding: 4em 0;
}
.contact-section-bottom {
  position: relative;
}
.contact-form {
  background: #1a1a1a;
  padding: 4.5em 3em;
  position: absolute;
  z-index: 1;
  width: 100%;
  top: 3em;
  input[type="text"] {
    width: 100%;
    color: $color_5;
    font-size: 16px;
    padding: 15px 15px;
    border: none;
    outline: none;
    margin: 0 0px 15px 0;
    background: #333333;
  }
  textarea {
    width: 100%;
    color: $color_5;
    font-size: 16px;
    padding: 15px 15px;
    border: none;
    outline: none;
    margin: 0 0px 15px 0;
    background: #333333;
    resize: none;
    width: 98%;
    height: 7.5em;
  }
  input[type="submit"] {
    color: $color_5;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 400;
    border: 1px solid #fff;
    padding: 9px 30px;
    outline: none;
    background: #1a1a1a;
    margin-top: 1em;
    &:hover {
      background: #329cd6;
    }
  }
}
.map {
  position: relative;
  margin-top: 13em;
  iframe {
    width: 100%;
    min-height: 500px;
  }
}
.map-layer {
  background: #1a1a1a;
  min-height: 500px;
  background: rgba(26, 26, 26, 0.48);
  min-height: 505px;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.footer-wrapper {
  min-height: 100%;
  margin-bottom: -75px;
}
.push {
  height: 75px;
}
.footer {
  background: #1a1a1a;
  padding: 0.5em 0;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}
.social-icons {
  i {
    width: 25px;
    height: 25px;
    background: url('assets/images/img-sprite.png') no-repeat -75px -150px;
    display: inline-block;
    &:hover {
      opacity: 0.5;
    }
  }
}
i.facebook {
  background-position: -75px -150px;
  margin: 0 10px;
}
i.twitter {
  background-position: -100px -150px;
  margin: 0 10px;
}
i.googleplus {
  background-position: -125px -150px;
  margin: 0 10px;
}
i.youtube {
  background-position: -150px -150px;
  margin: 0 10px;
}
i.linkedin {
  background-position: -175px -150px;
  margin: 0 10px;
}
.copyright {
  p {
    color: $color_5;
    font-size: 16px;
    font-weight: 300;
    margin-top: 1em;
    a {
      color: $color_5;
      font-size: 16px;
      font-weight: 300;
      margin-top: 1em;
    }
  }
}
.services_overview {
  h3 {
    color: $color_5;
    font-size: 2.7em;
    font-weight: 300;
    letter-spacing: 2px;
    margin-bottom: 0.8em;
  }
  background: #226464;
  padding: 4em 0;
}
.service_grid {
  padding: 0;
  width: 31.333%;
  margin-right: 3%;
  h4 {
    color: $color_13;
    font-size: 1.4em;
    font-weight: 500;
    text-transform: uppercase;
    margin: 20px 0 0 0;
  }
  p {
    color: $color_14;
    font-weight: 300;
    font-size: 1em;
    line-height: 1.8em;
    margin: 8px 0 0 0;
  }
}
.span_55 {
  margin-right: 0;
}
.s-map {
  margin-top: 0;
}
.who_we_are {
  padding: 3em 0;
}
.latest-news {
  padding: 3em 0;
  h3 {
    color: $color_6;
    font-size: 2.7em;
    font-weight: 300;
    letter-spacing: 2px;
    margin-bottom: 0.8em;
  }
}
.grid_12 {
  h3 {
    color: $color_6;
    font-size: 2.7em;
    font-weight: 300;
    letter-spacing: 2px;
    margin-bottom: 0.8em;
  }
  p {
    color: $color_10;
    font-weight: 400;
    font-size: 0.965em;
    line-height: 1.8em;
    margin: 8px 0 15px 0;
    max-width: 810px;
    margin: 0 auto 13px;
  }
  h5 {
    font-size: 1.11em;
    color: $color_15;
    line-height: 1.6em;
    margin: 0 auto 13px;
    max-width: 860px;
    font-weight: 600;
  }
}
.grid_5 {
  width: 35%;
  margin-left: 11%;
  margin-right: 4%;
  float: left;
  margin-top: 2%;
  img {
    width: 100%;
  }
}
.grid_6 {
  width: 35%;
  margin-right: 11%;
  margin-left: 4%;
  float: left;
  margin-top: 2%;
  img {
    width: 100%;
  }
}
.why_choose_us {
  padding: 3em 0;
  background: #226464;
  a {
    color: $color_7;
  }
  h3 {
    color: $color_5;
    font-size: 2.7em;
    font-weight: 300;
    letter-spacing: 2px;
    margin-bottom: 0.8em;
  }
}
.grid_4 {
  display: inline-flex;
  @media (max-width: 800px) {
    display: block;
  }
  span {
    margin-top: 15px;
    display: inline-block;
    width: 78px;
    height: 78px;
    background: #900909;
    color: $color_7;
    text-align: center;
    font-size: 24px;
    line-height: 78px;
    border-radius: 50%;
    position: relative;
  }
  p {
    color: $color_5;
    font-weight: 400;
    font-size: 0.965em;
    line-height: 1.8em;
    margin: 15px 0 0 0;
  }
}
.btn {
  border: none;
  font-family: $font-family_2;
  font-size: inherit;
  color: $color_16;
  background: none;
  padding: 0.4em 1.5em;
  display: inline-block;
  text-transform: uppercase;
  outline: none;
  position: relative;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  border-radius: 0px;
  text-decoration: none;
  background: #900909;
  color: $color_3;
  border: none;
  font-size: 1.1em;
  font-weight: 400;
  padding: 8px 20px;
  border-radius: 0;
  text-align: center;
  text-transform: capitalize;
  z-index: 1;
  /*
  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
  }
  */
  &:hover {
    background: #ffffff;
    color: $color_2;
  }
}
a.btn1 {
  border: none;
  font-family: $font-family_2;
  font-size: inherit;
  color: $color_16;
  background: none;
  padding: 0.8em 3em;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 1px;
  outline: none;
  position: relative;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  border-radius: 0px !important;
  text-decoration: none;
  font-size: 1.8em;
  border: 2px solid #fff;
  font-weight: 900;
  color: $color_5;
  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
  }
}
.btn-1c {
  &:after {
    width: 100%;
    height: 0;
    top: 0;
    left: 0;
    background: #fff;
  }
  &:hover {
    color: $color_17;
    &:after {
      height: 100%;
    }
  }
  &:active {
    color: $color_17;
    &:after {
      height: 100%;
    }
  }
}
.btn-1d {
  overflow: hidden;
  &:after {
    width: 0;
    height: 103%;
    top: 50%;
    left: 50%;
    background: rgba(92, 162, 204, 0.7);
    opacity: 0;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }
  &:hover {
    color: $color_5;
    &:after {
      width: 90%;
      opacity: 1;
    }
  }
  &:active {
    color: $color_5;
    &:after {
      width: 101%;
      opacity: 1;
    }
  }
}
.element {
  background: #226464;
  padding-bottom: 2em;
  p {
    color: $color_7;
    font-weight: 400;
    font-size: 0.965em;
    line-height: 1.8em;
    margin: 10px 0 0px 0;
    padding: 0 10px;
  }
  h4 {
    color: $color_15;
    font-weight: 500;
    font-size: 18px;
    text-transform: uppercase;
    margin-top: 39px;
    margin-bottom: 17px;
  }
}
.grid_7 {
  img {
    width: 100%;
  }
  a {
    color: $color_7;
  }
  p {
    color: $color_7;
  }
}
.collection-row {
  background-color: $background-color_1;
  padding: 1em;
}
.contact-form_grid {
  text-align: center;
  input[type="text"] {
    display: block;
    width: 99%;
    padding: 12px;
    outline: none;
    font-size: 14px;
    font-weight: bold;
    color: $color_18;
    background: #FFF;
    border: none;
    border-radius: 0.3em;
    -webkit-border-radius: 0.3em;
    -moz-border-radius: 0.3em;
    -o-border-radius: 0.3em;
    margin-bottom: 1em;
    font-family: $font-family_3;
    &:focus {
      color: $color_19;
    }
  }
  textarea {
    display: block;
    width: 99%;
    padding: 12px;
    outline: none;
    font-size: 14px;
    font-weight: bold;
    color: $color_18;
    background: #FFF;
    border: none;
    border-radius: 0.3em;
    -webkit-border-radius: 0.3em;
    -moz-border-radius: 0.3em;
    -o-border-radius: 0.3em;
    margin-bottom: 1em;
    font-family: $font-family_3;
    height: 170px;
    resize: none;
    &:focus {
      color: $color_19;
    }
  }
  input[type="submit"] {
    display: block;
    font-size: 18px;
    font-weight: 600;
    color: $color_20;
    text-transform: uppercase;
    padding: 12px 0px;
    width: 100%;
    background: #900909;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -o-border-radius: 5px;
    border: none;
    border-bottom: 4px solid #4b0505;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    -webkit-apperance: none;
    font-family: $font-family_3;
    outline: none;
    &:hover {
      background: #900909;
      border-bottom: 4px solid #4b0505;
    }
    &:active {
      position: relative;
      bottom: -2px;
    }
  }
}
.contact_right {
  background: #f1f1f1;
  padding: 5%;
}
.contact.about-desc {
  padding: 4em 0;
  h3 {
    color: $color_6;
    font-size: 2.7em;
    font-weight: 300;
    letter-spacing: 2px;
    margin-bottom: 1.5em;
    text-align: center;
  }
}
.contact_address {
  margin: 2em 0;
}
.contact_grid {
  h5 {
    color: $color_2;
    font-size: 1.5em;
  }
  p {
    color: $color_21;
    line-height: 1.8em;
  }
}
p.m_6 {
  color: $color_10;
  font-weight: 400;
  font-size: 0.965em;
  line-height: 1.8em;
  margin: 8px 0 15px 0;
  opacity: 0.7;
}
p.m_7 {
  color: $color_10;
  font-weight: 400;
  font-size: 0.965em;
  line-height: 1.8em;
  margin: 8px 0 15px 0;
  opacity: 0.7;
}
.contact_left {
  h4 {
    font-size: 2em;
    font-weight: 400;
    color: $color_2;
    text-transform: uppercase;
  }
}
.head_element {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: 1px solid #e6e6e6;
  text-align: center;
  padding-top: 7px;
  margin-right: 20px;
  margin-bottom: 17px;
  float: left;
  span {
    color: $color_2;
    font-size: 1.8em;
    display: block;
    margin: 0 auto;
    text-transform: uppercase;
  }
}
.alpha1 {
  width: 40%;
  margin-right: 3%;
  float: left;
  img {
    width: 100%;
  }
}
.sub {
  float: left;
  margin-top: 1.3em;
  p {
    color: $color_15;
    font-size: 1em;
    font-weight: 300;
  }
  a {
    &:hover {
      color: $color_2;
    }
  }
}
a.readblog {
  color: $color_15;
  font-size: 1.6em;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 1px;
  margin-bottom: 3px;
  text-decoration: none;
}
article.clearfix {
  margin: 2em 0 3em 0;
}
.blog-section {
  padding: 4em 0;
}
h2.with-line {
  color: $color_6;
  font-size: 2.7em;
  font-weight: 300;
  letter-spacing: 2px;
  margin-bottom: 0.8em;
  text-align: center;
}
.right_column {
  h2 {
    text-align: left;
    font-size: 2.3em;
    margin: 1em 0 0.4em 0;
  }
  li {
    line-height: 14px;
    padding: 7px 0 7px 18px;
    background: url('assets/images/bullet.png') left 16px no-repeat;
    font-size: 13px;
    list-style-type: none;
    a {
      color: $color_10;
      font-weight: 400;
      font-size: 0.965em;
      line-height: 1.8em;
      opacity: 0.6;
      text-decoration: none;
      &:hover {
        color: $color_2;
        padding-left: 10px;
      }
    }
  }
}
.grid_b4 {
  h5 {
    font-size: 1em;
    color: $color_15;
    line-height: 1.8em;
  }
  p {
    color: $color_10;
    font-weight: 400;
    font-size: 0.9em;
    line-height: 1.9em;
    margin: 15px 0 27px 0;
    opacity: 0.6;
  }
  float: left;
  width: 56%;
}
.left_column {
  width: 65%;
  padding: 0 50px 0 0;
}
.content-pagenation {
  text-align: center;
  li {
    display: inline-block;
    a {
      color: $color_2;
      font-size: 1em;
      background: #FFF;
      padding: 10px 12px;
      border: 1px solid #900909;
      -webkit-transition: all .5s;
      -moz-transition: all .5s;
      -o-transition: all .5s;
      -ms-transition: all .5s;
      transition: all .5s;
      &:hover {
        background: #900909;
        color: $color_20;
      }
    }
  }
}
.error-page {
  padding: 2em 0 5em 0;
}
.error-404 {
  padding-top: 8em;
  min-height: 650px;
  h2 {
    font-size: 18em;
    font-weight: 500;
    color: $color_2;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
  }
  p {
    color: $color_22;
    margin: 0;
    font-size: 1.4em;
    text-transform: uppercase;
    margin-top: 1em;
  }
}
a.b-home {
  background: #272e34;
  padding: 0.8em 3em;
  display: inline-block;
  color: $color_20;
  text-decoration: none;
  margin-top: 1em;
  &:hover {
    background: #900909;
  }
}
article.attach {
  margin-bottom: 1em;
}
p.text1 {
  color: $color_10;
  font-weight: 400;
  font-size: 0.9em;
  line-height: 1.9em;
  opacity: 0.6;
}
#commentform {
  input[type="text"] {
    padding: 8px 15px;
    width: 60%;
    color: $color_23;
    font-size: 1em;
    border: 1px solid #eee;
    background: #eee;
    outline: none;
    display: block;
    padding: 8px 15px;
    width: 60%;
    color: $color_23;
    font-size: 1em;
    border: 1px solid #eee;
    background: #eee;
    outline: none;
    display: block;
  }
  label {
    display: block;
    font-size: 1em;
    color: $color_24;
  }
  span {
    color: $color_25;
    position: absolute;
  }
  textarea {
    padding: 13px 20px;
    width: 60%;
    color: $color_23;
    font-size: 0.85em;
    outline: none;
    height: 150px;
    display: block;
    resize: none;
    font-weight: 400;
    border: 1px solid #eee;
    background: #eee;
  }
  input[type="submit"] {
    padding: 12px 50px;
    color: $color_20;
    background: #444;
    outline: none;
    border: none;
    text-transform: uppercase;
    cursor: pointer;
    -webkit-appearance: none;
    font-size: 1.2em;
    font-weight: 500;
    &:hover {
      background: #222;
    }
  }
  p {
    position: relative;
    padding: 8px 0;
    font-size: 1em;
  }
}
.leave {
  h4 {
    color: $color_15;
    font-size: 1.8em;
    font-weight: 400;
    letter-spacing: 1px;
    margin-bottom: 12px;
  }
}
.comments1 {
  margin-top: 3em;
  h4 {
    color: $color_15;
    font-size: 1.8em;
    font-weight: 400;
    letter-spacing: 1px;
    margin-bottom: 12px;
  }
}
.comments-main {
  border: 1px solid #D5D4D4;
  padding: 17px 0px;
  margin: 15px 0px 40px 0;
}
.cmnts-right {
  a {
    padding: 6px 12px;
    color: $color_5;
    font-weight: 600;
    font-size: 14px;
    background: #999;
    transition: 0.5s all;
    -webkit-transition: 0.5s all;
    -moz-transition: 0.5s all;
    -ms-transition: 0.5s all;
    -o-transition: 0.5s all;
    outline: none;
    &:hover {
      background: #333;
      text-decoration: none;
    }
  }
}
.Archives {
  margin-bottom: 3em;
}
.cmts-main-left {
  img {
    border: 3px solid#ccc;
  }
}
.cmts-main-right {
  h5 {
    font-size: 1em;
    color: $color_26;
    margin: 0px;
    font-weight: 600;
  }
  p {
    color: $color_10;
    font-weight: 400;
    font-size: 0.9em;
    line-height: 1.9em;
    opacity: 0.6;
    margin: 2px 0 15px 0px;
  }
}
.cmnts-left {
  p {
    font-size: 13px;
    color: $color_21;
    font-weight: 600;
    margin: 15px 0px;
  }
}
.twocol {
  column-count: 2;
  column-gap: 40px;
  max-width: 100%;
  column-width: auto;
  fieldset {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
  }
}
.threecol {
  max-width: 1600px;
  width: 98%;
  margin-left: 1%;
  column-count: 3;
  column-gap: 40px;
  column-width: auto;
  .group {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
  }
}
#transtable {
  background-color: $background-color_2;
}
#accordion {
  .panel {
    border: none;
    box-shadow: none;
    border-radius: 0;
    margin-bottom: 15px;
  }
  .panel-heading {
    padding: 0;
    border-radius: 0;
    border: none;
    text-align: left;
  }
  .panel-title {
    a {
      display: block;
      padding: 14px 30px 14px 70px;
      font-size: 16px;
      font-weight: bold;
      color: $color_5;
      background: #226464;
      position: relative;
      overflow: hidden;
      transition: all 0.5s ease 0s;
      &:before {
        content: "";
        width: 55px;
        height: 100%;
        background-color: $background-color_3;
        position: absolute;
        top: 0;
        left: -13px;
        transform: skewX(-25deg);
        transition: all 0.5s ease 0s;
      }
      &:after {
        content: "\f047";
        font-weight: 900;
        position: absolute;
        left: 10px;
        top: 50%;
        color: $color_5;
        transform: translateY(-50%);
      }
    }
    a.collapsed {
      background: #f8f8f8;
      color: $color_27;
      &:hover {
        color: $color_28;
        &:before {
          background: #226464;
        }
        &:after {
          color: $color_5;
        }
      }
      &:after {
        color: $color_29;
      }
    }
  }
  .panel-body {
    font-size: 14px;
    color: $color_30;
    line-height: 25px;
    padding: 20px 15px 20px 40px;
    position: relative;
    border: none;
    transition: all 0.5s ease 0s;
    text-align: left;
    &:before {
      content: "";
      width: 5px;
      height: 40px;
      background: #226464;
      position: absolute;
      top: 30px;
      left: 0;
    }
    p {
      margin-bottom: 0;
    }
    li.chair {
      font-weight: bold;
    }
  }
}
.board-table {
  tr {
    &:first-child {
      background-color: $background-color_1;
      color: $color_31;
      font-size: 18px;
    }
  }
}
@media screen and (max-width: 1366px) {
  .banner-info {
    width: 45%;
    left: 8%;
    top: 13%;
    padding: 2em 1.8em 2em 2em;
    h2 {
      font-size: 2.7em;
      letter-spacing: 2px;
      margin-bottom: 0.6em;
    }
    p {
      font-size: 1.4em;
      line-height: 1.45em;
    }
    a {
      font-size: 1.2em;
      padding: 9px 20px;
      width: 35%;
    }
  }
  .header-right {
    h4 {
      font-size: 1.9em;
      margin-bottom: 25px;
    }
  }
  .top-menu {
    ul {
      li {
        a {
          font-size: 1.05em;
        }
      }
    }
  }
  .logo {
    margin-top: 10px;
    width: 19%;
  }
  .banner {
    min-height: 421px;
  }
  .services-section {
    padding: 3em 0;
  }
  .subscribe-section {
    min-height: 200px;
  }
  .subscribe {
    h3 {
      font-size: 2.6em;
      margin-bottom: 0.7em;
    }
    input[type="text"] {
      font-size: 14px;
      padding: 10px;
    }
    a {
      font-size: 13px;
      padding: 11px 30px;
    }
  }
  .subscribe-section-grids {
    padding-top: 2.2em;
  }
  .book {
    img {
      width: 55%;
    }
  }
  .member1 {
    width: 16.7%;
  }
  .client-text {
    p {
      font-size: 1.5em;
    }
  }
  .nbs-flexisel-nav-left {
    margin-top: 7em;
    left: 30.5em;
  }
  .nbs-flexisel-nav-right {
    margin-top: 7em;
    right: 30.5em;
  }
  .description {
    min-height: 240px;
    width: 83%;
  }
  .testimonials-section {
    min-height: 417px;
  }
  .testimonials-section-head {
    margin: 2em 0 2.5em 0;
  }
  .map-layer {
    min-height: 455px;
  }
  .map {
    iframe {
      min-height: 450px;
    }
  }
  .contact-form {
    padding: 3.5em 3em;
  }
  .services_overview {
    padding: 3em 0;
  }
  .blog-section {
    padding: 3em 0 4em 0;
  }
  .contact.about-desc {
    padding: 3em 0;
    h3 {
      margin-bottom: 1.2em;
    }
  }
  .contact_left {
    h4 {
      font-size: 1.85em;
    }
  }
}
@media screen and (max-width: 1280px) {
  .map-layer {
    min-height: 405px;
  }
  .map {
    iframe {
      min-height: 400px;
    }
  }
  .logo {
    width: 18%;
  }
  .banner-info {
    width: 44%;
    left: 4.5%;
    padding: 1.5em 1.5em 1.8em 1.5em;
    h2 {
      font-size: 2.6em;
      letter-spacing: 1px;
      margin-bottom: 0.5em;
    }
    p {
      font-size: 1.35em;
      line-height: 1.4em;
    }
    a {
      font-size: 1.1em;
      margin-top: 1.8em;
    }
  }
  .banner {
    min-height: 389px;
  }
  .icons {
    margin-top: 1.5em;
  }
  .icons2 {
    margin-top: 6em;
  }
  .subscribe {
    h3 {
      font-size: 2.4em;
      margin-bottom: 0.6em;
    }
  }
  .subscribe-section-grids {
    padding-top: 1.8em;
  }
  .book {
    img {
      width: 50%;
    }
  }
  .subscribe-section {
    min-height: 171px;
  }
  .our-clients {
    padding: 2.5em 0;
  }
  .testimonials-section {
    min-height: 390px;
  }
  .contact-section {
    padding: 2em 0;
  }
  .who_we_are {
    padding: 2em 0 3em 0;
  }
  .latest-news {
    padding: 2em 0 3em 0;
  }
}
@media screen and (max-width: 1024px) {
  .threecol {
    column-count: 2;
    .twocol {
      column-count: 2;
    }
  }
  .header-right {
    h4 {
      font-size: 1.55em;
      margin-bottom: 20px;
    }
  }
  i.phone {
    width: 25px;
    height: 25px;
    background-size: 450px;
  }
  .top-menu {
    ul {
      li {
        a {
          font-size: 1em;
          padding: 5px 4px;
          &:hover {
            border-bottom: 4px solid #900909;
          }
        }
        a.active {
          border-bottom: 4px solid #900909;
        }
      }
    }
  }
  .header {
    padding: 0.5em 0;
  }
  .logo {
    width: 17%;
  }
  .banner-info {
    h2 {
      font-size: 2.15em;
      letter-spacing: 0px;
    }
    p {
      font-size: 1.25em;
    }
    a {
      font-size: 1em;
      margin-top: 1.6em;
      padding: 7px 15px;
      width: 39%;
    }
    top: 10%;
    left: 3.5%;
    padding: 1.3em 1.3em 1.6em 1.3em;
  }
  .banner {
    min-height: 315px;
  }
  .services-section {
    padding: 2em 0 3em 0;
    h3 {
      font-size: 2.5em;
      letter-spacing: 0px;
      margin-bottom: 0.55em;
    }
    h4 {
      font-size: 1.8em;
      letter-spacing: 1px;
    }
  }
  .services-section-grid1-top {
    padding: 10px 15px;
    margin-bottom: 10px;
  }
  .services-section-grid3-top {
    padding: 10px 15px;
    margin-bottom: 10px;
  }
  .services-section-grid1-bottom {
    padding: 10px 15px;
  }
  .services-section-grid3-bottom {
    padding: 10px 15px;
  }
  .services-section-grid2 {
    padding: 10px 15px;
  }
  .services-section-grid1 {
    padding: 0 10px 0 0;
  }
  .services-section-grid3 {
    padding: 0 0 0 10px;
  }
  .icons2 {
    margin-top: 4.8em;
  }
  .subscribe {
    h3 {
      font-size: 2.2em;
      letter-spacing: 1px;
    }
    input[type="text"] {
      padding: 8px;
    }
    a {
      padding: 9px 25px;
    }
  }
  .subscribe-section-grids {
    padding-top: 1.6em;
  }
  .subscribe-section {
    min-height: 150px;
  }
  .our-clients-head {
    h3 {
      font-size: 2.5em;
      letter-spacing: 1px;
      margin-bottom: 0.8em;
    }
  }
  .description {
    min-height: 205px;
    width: 82%;
    padding: 1.6em 0 0 0;
  }
  .member1 {
    width: 18%;
  }
  .client-text {
    p {
      font-size: 1.4em;
    }
    padding: 0 3em;
  }
  .nbs-flexisel-nav-right {
    right: 24.5em;
    margin-top: 6em;
  }
  .nbs-flexisel-nav-left {
    left: 24.5em;
    margin-top: 6em;
  }
  .testimonials-section-head {
    h3 {
      font-size: 2.4em;
      letter-spacing: 1px;
    }
    margin: 1.5em 0 1.5em 0;
  }
  .contact-section {
    h3 {
      font-size: 2.5em;
      letter-spacing: 1px;
      margin-bottom: 0.8em;
    }
  }
  i.smartphone {
    margin-bottom: 1em;
  }
  i.user {
    margin-bottom: 1em;
  }
  i.global {
    margin-bottom: 1em;
  }
  .testimonials-section {
    min-height: 315px;
  }
  .text {
    h4 {
      font-size: 1.1em;
      margin-bottom: 2px;
    }
    p {
      font-size: 0.95em;
    }
    padding: 8px;
  }
  .contact-top {
    p {
      font-size: 1.15em;
    }
  }
  .footer {
    padding: 2em 0;
  }
  .map-layer {
    min-height: 355px;
  }
  .map {
    iframe {
      min-height: 350px;
    }
    margin-top: 10em;
  }
  .contact-form {
    input[type="text"] {
      font-size: 14px;
      padding: 12px 12px;
    }
    textarea {
      font-size: 14px;
      padding: 12px 12px;
    }
    padding: 2.5em 3em;
  }
  .error-404 {
    padding-top: 6em;
    min-height: 590px;
    h2 {
      font-size: 17em;
    }
  }
  .grid_12 {
    h3 {
      font-size: 2.6em;
      letter-spacing: 1px;
      margin-bottom: 0.5em;
    }
  }
  .s-map {
    margin-top: 0;
  }
  .why_choose_us {
    padding: 2em 0 3em 0;
    h3 {
      font-size: 2.5em;
      letter-spacing: 1px;
      margin-bottom: 0.5em;
    }
  }
  .latest-news {
    h3 {
      font-size: 2.5em;
      letter-spacing: 1px;
      margin-bottom: 0.5em;
    }
  }
  .element {
    h4 {
      font-size: 17px;
      margin-top: 25px;
      margin-bottom: 10px;
    }
    p {
      font-size: 0.95em;
      line-height: 1.6em;
    }
  }
  .btn {
    font-size: 1em;
    padding: 7px 18px;
  }
  .services_overview {
    h3 {
      font-size: 2.5em;
      letter-spacing: 1px;
      margin-bottom: 0.5em;
    }
    padding: 2em 0;
  }
  .service_grid {
    h4 {
      font-size: 1.21em;
      margin: 15px 0 0 0;
    }
    p {
      font-size: 0.95em;
      line-height: 1.7em;
      margin: 5px 0 20px 0;
    }
  }
  p.m_6 {
    margin: 8px 0;
  }
  .contact.about-desc {
    h3 {
      margin-bottom: 1em;
      font-size: 2.6em;
      letter-spacing: 1px;
    }
  }
  .contact_left {
    h4 {
      font-size: 1.75em;
    }
  }
  .head_element {
    span {
      font-size: 1.5em;
    }
    width: 80px;
    height: 80px;
  }
  .sub {
    h3 {
      font-size: 1.45em;
      letter-spacing: 1px;
      margin-bottom: 2px;
    }
    p {
      font-size: 0.95em;
    }
  }
  .grid_b4 {
    h5 {
      line-height: 1.5em;
    }
    p {
      line-height: 1.8em;
      margin: 5px 0 15px 0;
    }
  }
  .blog-section {
    padding: 2em 0 3em 0;
  }
  article.clearfix {
    margin: 1em 0 3em 0;
  }
  h2.with-line {
    font-size: 2.65em;
    letter-spacing: 1px;
    margin-bottom: 0em;
  }
  .right_column {
    h2 {
      font-size: 2.2em;
      margin: 1em 0 0.4em 0;
      margin-bottom: 0.4em;
    }
    li {
      padding: 5px 0 5px 18px;
      background: url('assets/images/bullet.png') left 13px no-repeat;
    }
  }
  .left_column {
    padding: 0 25px 0 0;
  }
  .cmts-main-left {
    padding: 0 0 0 8px;
    width: 16.666%;
    img {
      width: 100%;
    }
  }
  .cmts-main-right {
    p {
      height: 63px;
      overflow: hidden;
      line-height: 1.7em;
    }
  }
  .cmnts-left {
    p {
      height: 24px;
      margin: 0;
    }
  }
  a.readblog {
    font-size: 1.4em;
  }
}
@media screen and (max-width: 800px) {
  .header-right {
    h4 {
      display: none;
    }
    width: 100%;
  }
  .banner-info {
    width: 68%;
    width: 47%;
    padding: 15px 11px 20px 17px;
    h2 {
      font-size: 1.8em;
    }
    p {
      font-size: 1em;
    }
    a {
      font-size: 0.95em;
      margin-top: 1.5em;
      padding: 7px 0px;
      border: 1px solid #fff;
      width: 42%;
    }
  }
  .services-section-grid1-bottom {
    width: 49%;
    float: left;
  }
  .services-section-grid3-bottom {
    width: 49%;
    float: left;
  }
  .services-section-grid1-top {
    width: 49%;
    float: left;
    margin-right: 2%;
    margin-bottom: 0;
  }
  .services-section-grid3-top {
    width: 49%;
    float: left;
    margin-right: 2%;
    margin-bottom: 0;
  }
  .services-section-grid1 {
    width: 100%;
    float: left;
    padding: 0;
  }
  .services-section-grid3 {
    width: 100%;
    float: left;
    padding: 0;
  }
  .services-section-grid2 {
    width: 100%;
    float: left;
    margin: 2% 0;
  }
  .icons2 {
    margin-top: 2em;
  }
  .subscribe {
    width: 70%;
    float: left;
    h3 {
      font-size: 1.8em;
      letter-spacing: 0px;
    }
    a {
      padding: 7px 18px;
      border: 1px solid #fff;
    }
    input[type="text"] {
      padding: 6px;
      border: 1px solid #fff;
    }
  }
  .book {
    width: 30%;
    float: left;
    img {
      width: 70%;
    }
  }
  .subscribe-section-grids {
    padding-top: 1.4em;
  }
  .subscribe-section {
    min-height: 125px;
  }
  .contact-section-grid {
    width: 33.333%;
    float: left;
  }
  .contact-top {
    p {
      font-size: 1em;
    }
  }
  .contact-left {
    width: 50%;
    float: left;
  }
  .contact-right {
    width: 50%;
    float: left;
  }
  .contact-form {
    padding: 2em 1em;
    input[type="submit"] {
      font-size: 14px;
      padding: 6px 25px;
      margin-top: 0.5em;
    }
    input[type="text"] {
      font-size: 13px;
      padding: 12px 10px;
    }
    textarea {
      font-size: 13px;
      padding: 12px 10px;
    }
  }
  .map-layer {
    min-height: 305px;
  }
  .map {
    iframe {
      min-height: 300px;
    }
  }
  .copyright {
    p {
      font-size: 15px;
      margin-top: 0.5em;
    }
  }
  .contact-section {
    h3 {
      font-size: 2.3em;
      letter-spacing: 0px;
    }
  }
  .description {
    padding: 1.2em 0 0 0;
    float: left;
    width: 80%;
    min-height: 182px;
  }
  .member1 {
    width: 20%;
  }
  .client-text {
    p {
      font-size: 1.2em;
    }
  }
  .testimonials-section-head {
    h3 {
      font-size: 2.1em;
      letter-spacing: 0px;
    }
  }
  .our-clients-head {
    h3 {
      font-size: 2.3em;
      letter-spacing: 0px;
    }
  }
  .banner {
    min-height: 240px;
  }
  .services-section {
    h3 {
      font-size: 2.3em;
      margin-bottom: 0.5em;
    }
  }
  .header {
    border-bottom: 3px solid #900909;
    padding: 17px 0;
  }
  .logo {
    margin-top: 0;
    width: 22%;
  }
  .text {
    p {
      font-size: 0.875em;
    }
  }
  .nbs-flexisel-nav-left {
    margin-top: 5em;
    left: 17.5em;
  }
  .nbs-flexisel-nav-right {
    margin-top: 5em;
    right: 17.5em;
  }
  .testimonials-section {
    min-height: 290px;
  }
  span.menu {
    width: 28px;
    height: 28px;
    background: url('assets/images/nav_on_white.png')no-repeat;
    display: inline-block;
    float: right;
    cursor: pointer;
    margin-top: -38px;
  }
  .top-menu {
    width: 100%;
    display: none;
    text-align: center;
    background: #fff;
    padding: 0;
    margin-top: 10px;
    ul {
      float: none;
      width: 100%;
      li {
        display: block;
        float: none;
        a {
          color: $color_12;
          display: block;
          padding: 10px 0px;
          border-bottom: 3px solid #fff;
          &:hover {
            border-bottom: 3px solid #900909;
          }
        }
        a.active {
          border-bottom: 3px solid #900909;
        }
      }
    }
  }
  .error-404 {
    h2 {
      font-size: 15em;
    }
  }
  .grid_12 {
    h5 {
      font-size: 1.05em;
      height: 46px;
      overflow: hidden;
    }
    p {
      overflow: hidden;
    }
    h3 {
      font-size: 2.5em;
      letter-spacing: 0px;
      margin-bottom: 0.4em;
    }
  }
  .grid_7 {
    width: 33.333%;
    float: left;
    padding: 0 8px;
  }
  .grid_4 {
    width: 33.333%;
    float: left;
    padding: 0 10px;
    span {
      margin-top: 10px;
      width: 65px;
      height: 65px;
      font-size: 22px;
      line-height: 65px;
    }
    p {
      font-size: 0.9em;
    }
  }
  .element {
    p {
      margin: 0px 0 0px 0;
    }
    h4 {
      margin-top: 15px;
      margin-bottom: 5px;
    }
    padding-bottom: 1.5em;
  }
  .why_choose_us {
    h3 {
      font-size: 2.3em;
      letter-spacing: 0px;
    }
  }
  .latest-news {
    h3 {
      font-size: 2.3em;
      letter-spacing: 0px;
    }
  }
  .btn {
    font-size: 0.95em;
    padding: 5px 15px;
  }
  .left_column {
    width: 100%;
    padding: 0 0px 0 0;
  }
  .grid_b4 {
    h5 {
      line-height: 1.8em;
    }
    p {
      line-height: 1.9em;
      margin: 10px 0 20px 0;
    }
  }
  .service_grid {
    width: 32%;
    margin-right: 2%;
    float: left;
    h4 {
      font-size: 0.94em;
      margin: 12px 0 0 0;
    }
    p {
      margin: 5px 0 0px 0;
      height: 67px;
      overflow: hidden;
    }
  }
  .span_55 {
    margin-right: 0;
  }
  .services_overview {
    h3 {
      font-size: 2.3em;
      letter-spacing: 0px;
      margin-bottom: 0.6em;
    }
  }
  h2.with-line {
    font-size: 2.5em;
    letter-spacing: 0px;
  }
  .cmts-main-left {
    margin-right: 4%;
    float: left;
    padding: 0 0 0 15px;
  }
  .cmnts-left {
    float: left;
  }
  .contact_right {
    margin-top: 5%;
    padding: 4%;
  }
  .contact.about-desc {
    h3 {
      margin-bottom: 0.8em;
      font-size: 2.5em;
      letter-spacing: 0px;
    }
    padding: 2em 0;
  }
  .contact_left {
    h4 {
      font-size: 1.65em;
    }
  }
  .right_column {
    h2 {
      font-size: 2em;
    }
  }
}
@media screen and (max-width: 768px) {
  .banner {
    min-height: 234px;
  }
  .error-404 {
    h2 {
      font-size: 14em;
    }
    padding-top: 5em;
    min-height: 530px;
  }
  span.menu {
    margin-top: -32px;
  }
}
@media screen and (max-width: 640px) {
  .logo {
    width: 24%;
  }
  .banner-info {
    width: 50%;
    padding: 10px 10px 15px 10px;
    top: 8%;
    left: 2.5%;
    h2 {
      font-size: 1.45em;
    }
    p {
      font-size: 0.875em;
    }
    a {
      font-size: 0.875em;
      margin-top: 1em;
      padding: 5px 0px;
      width: 47%;
    }
  }
  .banner {
    min-height: 195px;
  }
  .map-layer {
    min-height: 255px;
  }
  .map {
    iframe {
      min-height: 250px;
    }
  }
  .footer {
    padding: 1.5em 0;
  }
  .header {
    padding: 15px 0;
  }
  .services-section {
    h3 {
      font-size: 2.1em;
    }
    h4 {
      font-size: 1.5em;
      letter-spacing: 0px;
    }
    p {
      font-size: 0.95em;
      margin: 8px 0 0px 0;
    }
    padding: 1.5em 0 2em 0;
  }
  i.call {
    width: 40px;
    height: 50px;
    background: url('assets/images/img-sprite.png') no-repeat -28px 0px;
    background-size: 450px;
  }
  i.callmsg {
    width: 55px;
    height: 50px;
    background: url('assets/images/img-sprite.png') no-repeat -72px -2px;
    background-size: 450px;
  }
  .arrow2 {
    margin-top: 14px;
  }
  .arrow1 {
    margin-top: 12px;
  }
  i.arrow {
    width: 30px;
    height: 30px;
    background: url('assets/images/img-sprite.png') no-repeat -392px 0px;
    background-size: 500px;
  }
  i.interact {
    width: 100px;
    height: 100px;
    background: url('assets/images/img-sprite.png') no-repeat -125px 2px;
    background-size: 450px;
  }
  .arrow3 {
    margin-top: 40px;
  }
  i.dt {
    width: 65px;
    height: 55px;
    background: url('assets/images/img-sprite.png') no-repeat -231px 0px;
    background-size: 450px;
  }
  i.zoom {
    width: 50px;
    height: 55px;
    background: url('assets/images/img-sprite.png') no-repeat -302px -2px;
    background-size: 450px;
  }
  .icons2 {
    margin-top: 1em;
  }
  .icons {
    margin-top: 1em;
  }
  i.smartphone {
    width: 55px;
    height: 55px;
    background: url('assets/images/img-sprite.png') no-repeat -1px -46px;
    background-size: 450px;
  }
  i.user {
    width: 55px;
    height: 55px;
    background: url('assets/images/img-sprite.png') no-repeat 0px -103px;
    background-size: 450px;
  }
  i.global {
    width: 55px;
    height: 55px;
    background: url('assets/images/img-sprite.png') no-repeat -56px -58px;
    background-size: 450px;
  }
  .subscribe {
    h3 {
      font-size: 1.5em;
      margin-bottom: 0.4em;
    }
    a {
      padding: 5px 10px;
      font-size: 12px;
    }
    input[type="text"] {
      padding: 4px;
      font-size: 13px;
    }
  }
  .book {
    img {
      width: 65%;
    }
  }
  .subscribe-section-grids {
    padding-top: 1.1em;
  }
  .subscribe-section {
    min-height: 100px;
  }
  .our-clients-head {
    h3 {
      font-size: 2.1em;
      margin-bottom: 0.6em;
    }
  }
  .contact-form {
    input[type="text"] {
      font-size: 12px;
      padding: 10px 10px;
    }
    textarea {
      font-size: 12px;
      padding: 10px 10px;
    }
    input[type="submit"] {
      font-size: 13px;
      padding: 6px 23px;
      margin-top: 0.3em;
    }
    padding: 1.5em 1em;
  }
  .client-text {
    p {
      font-size: 1em;
    }
  }
  .text {
    h4 {
      font-size: 1em;
      margin-bottom: 0px;
    }
    p {
      font-size: 0.7em;
    }
    padding: 8px 0;
  }
  .description {
    padding: 1em 0 0 0;
    min-height: 153px;
  }
  .nbs-flexisel-nav-right {
    right: 13.5em;
  }
  .nbs-flexisel-nav-left {
    left: 13.5em;
  }
  .testimonials-section-head {
    h3 {
      font-size: 2em;
    }
    margin: 1em 0;
  }
  .testimonials-section {
    min-height: 250px;
  }
  .error-404 {
    h2 {
      font-size: 12em;
    }
    padding-top: 4em;
    min-height: 480px;
  }
  .element {
    h4 {
      margin-top: 10px;
      margin-bottom: 3px;
      font-size: 1.12em;
    }
    p {
      margin: 0px 0 0 0;
      height: 60px;
      overflow: hidden;
    }
  }
  .why_choose_us {
    h3 {
      font-size: 2em;
      letter-spacing: 0px;
    }
  }
  .latest-news {
    h3 {
      font-size: 2em;
      letter-spacing: 0px;
    }
  }
  .grid_12 {
    h3 {
      font-size: 2.3em;
    }
    h5 {
      font-size: 1em;
      height: 42px;
    }
    p {
      overflow: hidden;
      line-height: 1.7em;
      font-size: 0.875em;
    }
  }
  .grid_4 {
    p {
      height: 80px;
      overflow: hidden;
      line-height: 1.7em;
      font-size: 0.875em;
      margin: 15px 0 0px 0;
    }
    span {
      margin-top: 5px;
      width: 60px;
      height: 60px;
      font-size: 21px;
      line-height: 60px;
    }
  }
  .services_overview {
    h3 {
      font-size: 2em;
      margin-bottom: 0.5em;
    }
    padding: 1.5em 0;
  }
  .content-pagenation {
    margin-top: 2em;
  }
  .contact.about-desc {
    h3 {
      margin-bottom: 0.5em;
      font-size: 2.3em;
    }
  }
  .contact_left {
    h4 {
      font-size: 1.5em;
    }
  }
  p.m_6 {
    margin: 6px 0;
    font-size: 0.9em;
  }
  p.m_7 {
    font-size: 0.9em;
  }
}
@media screen and (max-width: 480px) {
  .logo {
    width: 27%;
  }
  span.menu {
    width: 21px;
    height: 21px;
    background-size: 21px;
    margin-top: 0;
  }
  .top-menu {
    width: 100%;
    display: none;
    &.show {
      display: block;
    }
    text-align: center;
    background: #fff;
    padding: 0;
    margin-top: 10px;
    ul {
      float: none;
      width: 100%;
      li {
        display: block;
        float: none;
        a {
          color: $color_12;
          display: block;
          padding: 10px 0;
          border-bottom: 3px solid #fff;
          &:hover {
            border-bottom: 3px solid #900909;
          }
        }
        a.active {
          border-bottom: 3px solid #900909;
        }
      }
    }
  }
  .banner-info {
    p {
      display: none;
    }
    h2 {
      font-size: 1.2em;
    }
    a {
      font-size: 0.75em;
      width: 54%;
      margin-top: 1.8em;
    }
    width: 48%;
    top: 18%;
    padding: 10px 10px 15px 11px;
  }
  .banner {
    min-height: 170px;
  }
  .header {
    padding: 10px 0;
    border-bottom: 2px solid #900909;
  }
  .subscribe {
    padding: 0;
    h3 {
      font-size: 1.15em;
    }
    a {
      padding: 4px 6px;
      font-size: 12px;
      letter-spacing: 1px;
    }
    input[type="text"] {
      padding: 3px;
    }
  }
  .services-section {
    h3 {
      font-size: 1.9em;
    }
    padding: 1em 0 1.5em 0;
  }
  .book {
    img {
      width: 75%;
    }
  }
  .subscribe-section {
    min-height: 90px;
  }
  .our-clients {
    padding: 1.5em 0;
  }
  .contact-section-grid {
    width: 100%;
    margin-top: 2em;
  }
  i.smartphone {
    margin-bottom: 0.3em;
  }
  i.user {
    margin-bottom: 0.3em;
  }
  i.global {
    margin-bottom: 0.3em;
  }
  .contact-section {
    h3 {
      font-size: 2em;
      margin-bottom: -0.2em;
    }
  }
  .map-layer {
    min-height: 205px;
  }
  .map {
    iframe {
      min-height: 200px;
    }
  }
  .contact-form {
    padding: 1.2em 0em;
    input[type="text"] {
      margin: 0 0px 10px 0;
      padding: 8px 8px;
    }
    textarea {
      margin: 0 0px 10px 0;
      padding: 8px 8px;
      height: 6.5em;
    }
  }
  .client-text {
    p {
      height: 60px;
      overflow: hidden;
    }
  }
  .nbs-flexisel-nav-right {
    right: 9.5em;
  }
  .nbs-flexisel-nav-left {
    left: 9.5em;
  }
  .description {
    min-height: 137px;
  }
  .testimonials-section {
    display: none;
  }
  .error-404 {
    h2 {
      font-size: 9em;
    }
    p {
      font-size: 1.2em;
    }
    padding-top: 3em;
    min-height: 380px;
  }
  a.b-home {
    padding: 0.6em 2em;
    margin-top: 0.8em;
  }
  .grid_12 {
    h3 {
      font-size: 2em;
    }
    h5 {
      font-size: 0.95em;
      height: 39px;
      line-height: 1.5em;
    }
  }
  .who_we_are {
    padding: 1em 0 2em 0;
  }
  .latest-news {
    padding: 1em 0 2em 0;
  }
  .why_choose_us {
    padding: 1.2em 0 2em 0;
  }
  .grid_7 {
    width: 100%;
    padding: 0 50px;
    margin-bottom: 2em;
  }
  .grid_4 {
    width: 100%;
    padding: 0 50px;
    margin-bottom: 2em;
    p {
      height: 60px;
    }
  }
  .service_grid {
    width: 100%;
    margin: 0 0 2em 0;
  }
  .contact.about-desc {
    padding: 1.2em 0;
    h3 {
      margin-bottom: 0.4em;
      font-size: 2em;
    }
  }
  .contact_left {
    h4 {
      font-size: 1.35em;
    }
  }
  .head_element {
    span {
      font-size: 1.2em;
    }
    width: 70px;
    height: 70px;
  }
  .sub {
    h3 {
      font-size: 1.2em;
      letter-spacing: 0px;
      margin-bottom: 0px;
    }
    margin-top: 1.1em;
    p {
      font-size: 0.875em;
      margin-top: 0px;
    }
  }
  h2.with-line {
    font-size: 2.2em;
  }
  .grid_b4 {
    h5 {
      line-height: 1.6em;
      font-size: 0.9em;
    }
    p {
      line-height: 1.7em;
      margin: 5px 0 15px 0;
      height: 40px;
      overflow: hidden;
    }
  }
  .right_column {
    h2 {
      font-size: 1.85em;
    }
  }
  .content-pagenation {
    li {
      a {
        font-size: 0.875em;
        padding: 5px 10px;
      }
    }
  }
  #commentform {
    input[type="text"] {
      padding: 6px 12px;
      width: 75%;
    }
    textarea {
      padding: 6px 12px;
      width: 75%;
    }
    input[type="submit"] {
      padding: 9px 45px;
    }
  }
  .comments1 {
    h4 {
      font-size: 1.5em;
    }
  }
  .contact_grid {
    h5 {
      font-size: 1.3em;
    }
  }
  a.readblog {
    font-size: 1.2em;
    letter-spacing: 0px;
  }
}
@media screen and (max-width: 320px) {
  .logo {
    width: 38%;
  }
  span.menu {
    margin-top: -23px;
  }
  .banner-info {
    h2 {
      font-size: 0.95em;
    }
    width: 57%;
    padding: 5px 5px 10px 5px;
    a {
      font-size: 0.7em;
      width: 67%;
      margin-top: 1em;
    }
  }
  .banner {
    min-height: 120px;
  }
  .services-section {
    h3 {
      font-size: 1.75em;
    }
  }
  .services-section-grid1-top {
    width: 100%;
    margin-right: 0;
    margin-bottom: 2%;
  }
  .services-section-grid3-top {
    width: 100%;
    margin-right: 0;
    margin-bottom: 2%;
  }
  .services-section-grid1-bottom {
    width: 100%;
  }
  .services-section-grid3-bottom {
    width: 100%;
  }
  .book {
    img {
      display: none;
    }
  }
  .subscribe {
    width: 90%;
  }
  .our-clients-head {
    h3 {
      font-size: 1.8em;
    }
  }
  .copyright {
    p {
      font-size: 13px;
      a {
        font-size: 15px;
        margin-top: 0.3em;
        display: block;
      }
    }
  }
  .footer {
    padding: 1em 0;
  }
  .map-layer {
    min-height: 155px;
  }
  .map {
    iframe {
      min-height: 150px;
    }
  }
  .contact-left {
    width: 100%;
  }
  .contact-right {
    width: 100%;
  }
  .contact-form {
    input[type="submit"] {
      font-size: 12px;
      padding: 5px 20px;
    }
    top: 1em;
  }
  .description {
    min-height: 136px;
    width: 62%;
  }
  .member1 {
    width: 38%;
  }
  .client-text {
    p {
      width: 100%;
    }
  }
  .nbs-flexisel-nav-right {
    right: 2.5em;
  }
  .nbs-flexisel-nav-left {
    left: 2.5em;
  }
  .grid_4 {
    padding: 0 10px;
    margin-bottom: 1.8em;
  }
  .grid_7 {
    padding: 0 10px;
    margin-bottom: 1.8em;
  }
  .grid_5 {
    width: 47%;
    margin-left: 0%;
    margin-right: 2%;
  }
  .grid_6 {
    width: 48%;
    margin-right: 0%;
    margin-left: 2%;
  }
  .grid_12 {
    h3 {
      font-size: 1.85em;
    }
  }
  .right_column {
    padding: 0;
    h2 {
      font-size: 1.8em;
    }
  }
  .alpha1 {
    width: 75%;
    margin-right: 0;
    margin-bottom: 0.8em;
  }
  .grid_b4 {
    width: 100%;
  }
  .head_element {
    span {
      font-size: 1em;
    }
    width: 55px;
    height: 55px;
    margin-right: 5px;
    margin-bottom: 10px;
  }
  .sub {
    margin-top: 0.7em;
  }
  h2.with-line {
    font-size: 2em;
  }
  .blog-section {
    padding: 1em 0 2em 0;
  }
  .contact_left {
    h4 {
      font-size: 1.1em;
    }
  }
  .contact.about-desc {
    h3 {
      font-size: 1.8em;
    }
  }
  p.m_6 {
    height: 67px;
    overflow: hidden;
  }
  .contact-form_grid {
    input[type="submit"] {
      font-size: 16px;
      padding: 9px 0px;
    }
  }
  .contact_address {
    margin: 1.5em 0;
  }
  .leave {
    h4 {
      font-size: 1.6em;
      letter-spacing: 0px;
      margin-bottom: 5px;
    }
  }
  #commentform {
    input[type="text"] {
      padding: 5px 10px;
      width: 100%;
    }
    textarea {
      padding: 5px 10px;
      width: 100%;
    }
  }
  .cmnts-left {
    width: 65%;
  }
  .comments1 {
    margin-top: 1.5em;
  }
  .top-menu {
    ul {
      li {
        a {
          &:hover {
            border-bottom: 2px solid #900909;
          }
          font-size: 0.95em;
          padding: 7px 0px;
          border-bottom: 2px solid #fff;
        }
        a.active {
          border-bottom: 2px solid #900909;
        }
      }
    }
  }
  a.readblog {
    font-size: 0.92em;
  }
}

.wrapper {
  width: 100%;
  height: 100%;
}

.overlay {
  position: fixed;
  z-index: 1002;
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 50px;
}

.large-overlay {
  height: 100%;
}

.full-size-spinner {
  width: 100%;
  height: 50px;
}

.spinner-wrapper {
  display: flex;
  justify-content: center;
  justify-items: center;
}

.action-spinner {
  height: 30px;
  width: 30px;
  margin: auto;
  position: absolute;
  top: 0;
  z-index: 1;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  border: 3px solid rgba(255, 255, 255, 0);
  border-top: 3px solid #FFF;
  border-right: 3px solid #FFF;
  -webkit-animation: spinner 2s infinite cubic-bezier(0.085, 0.625, 0.855, 0.36);
  animation: spinner 2s infinite cubic-bezier(0.085, 0.625, 0.855, 0.36);
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


table.dataTable td {
  font-size: 12px;
}
table.dataTable tbody td {
  padding: 2px 6px !important;
  @media screen and (max-width: 800px) {
    padding: 8px 10px !important;
  }
}

table.summary-table {
  overflow-y: hidden;
  td {
    font-size: 12px;
  }

  tbody {
    td {
      padding: 2px 6px !important;
      @media screen and (max-width: 800px) {
        padding: 8px 10px !important;
      }
    }
  }
}

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

app-modal {
  /* modals are hidden by default */
  display: none;
}
app-modal-large {
  /* modals are hidden by default */
  display: none;
}

body.modal-open {
  /* body overflow is hidden to hide main scrollbar when modal window is open */
  overflow: hidden;
}

.pedText {
  font-family: Arial, Chicago, Sans-serif;
  font-size: 12pt;
  font-style: normal;
  text-align: left;
  line-height: 15pt;
  pre
  {
    display:inline;
    margin:0;
  }

}

table.top-section {
  td {
    width: 50%;
    line-height: 1.3em;
    margin: 0 10px;
    font-size: 11px;
    text-align: end;
    padding: 0 5px;
  }
  td:nth-last-child(1) {
    line-height: 1.3em;
    margin: 0 10px;
    font-size: 11px;
    text-align: start;
  }
}

.table-container {
  padding: 0 50px;
  @media (max-width: 800px) {
    padding: 0;
  }
}


.pedigree-export-view {
  z-index: -10;
  position: absolute;
  left: 1.5rem;
  top: 0;
  background-image: url('assets/images/NCAtrans900909Opaque.png');
  background-repeat: no-repeat;
  background-size: contain;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

/* Custom stuff */
.customUploadClass .img-ul-file-upload.img-ul-hr-inline-group .img-ul-upload {
  background-color: #327474; }

div.customUploadClass {
  background-color: #f8f8f8;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin: 5px;
  border: thin dashed black; }

.customUploadClass .img-ul-container {
  background-color: #f8f8f8; }
